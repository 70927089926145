import React, { useEffect, useRef, useState } from 'react';

// Components
import { Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Custom components
import { BackdropLoading } from '../../components/BackdropLoading';
import { PageTitle } from '../../components/PageTitle';
import { CardGraphic } from '../../components/CardGraphic';
import { LoadingProgress } from '../../components/LoadingProgress';
import TableModel from '../../components/atoms/tables/TableModel';
import { StatusCell } from '../../components/atoms/statusCell';
import { SelectInput } from '../../components/SelectInput';
import NestedApiCall from './NestedApiCall';
import { Button } from "@mui/material";


// Styles
import { Container, CustomButton } from '../styles';
import {
    GridCard,
    Ul,
    Li,
    HtmlTooltip,
    Title,
    ContainerTitle,
    ContainerActions,
    ContainerLeft,
    ContainerRight,
    ContainerGraphics,
    ContainerContent,
    ContainerGroup,
    ContainerCard,
    ContainerButtonDownload,
} from './styles';

// Service
import { DashboardReportApi } from '../../services/DashboardReportApi';
import { WorkflowApi } from '../../services/WorkflowApi';
import Router from '../../router';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'
import { FormatDate } from '../../utils/formatDate';

// Icons
import { Assessment, CloudDownloadOutlined, Info } from '@mui/icons-material';

export const DashboardReport = (props) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    const dashboardReportApi = new DashboardReportApi();
    const workflowApi = new WorkflowApi();
    const { format } = new FormatDate();


    const { check } = new CheckResponse();
    const navigate = useNavigate()

    // Selected
    const [selected, setSelected] = useState({
        workflow: null,
        plotPlan: null,
        status: null,
        created: null,
        workflowSelect: { id: null, name: '' }
    });

    // Graphic
    const [flowData, setFlowData] = useState([]);
    const [flowOptions, setFlowOptions] = useState({});
    const [flowSeries, setFlowSeries] = useState([]);
    const [plotPlanList, setPlotPlanList] = useState([]);
    const [stateOptions, setStateOptions] = useState({});
    const [stateSeries, setStateSeries] = useState([]);
    const [openInstanceOptions, setOpenInstanceOptions] = useState({});
    const [openInstanceSeries, setOpenInstanceSeries] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingPlotPlan, setLoadingPlotPlan] = useState(false);

    const chartRef = useRef(null)

    // Table
    const [tableList, setTableList] = useState([]);

    // Plot plans
    const [workflows, setWorkflows] = useState([]);
    const iterationCounter = useRef(0);

    useEffect(() => {
        async function fetchData() {
            await listWorkflow()
        }
        fetchData()
    }, [])

    useEffect(() => {
        setLoading(true)
        iterationCounter.current += 1;
        async function fetchData() {
            await dashboardByWorkflow()
            await dashboardByPlotPlan(1, workflows[0]?.id)
            await dashboardByCreated(null, workflows[0]?.id)
            await dashboardByState(null, workflows[0]?.id)
        }

        if (workflows.length > 0) {
          fetchData()
        }

        setLoading(false)
    }, [workflows])

    useEffect(() => {
        async function call() {
            await getTable()
        }
        call()
    }, [selected.plotPlan, selected.workflow, selected.status, selected.created])

    function initStateSeries(message) {
        let text = message ? message : [t('dashboard.graphics.status.selectFlow1'), t('dashboard.graphics.status.selectFlow2')]
        let opts = options([], 'Estados', 'status', 'title')

        opts = {
            ...opts,
            colors: ['#ccc'],
            noData: {
                text: text,
                align: 'center',
                verticalAlign: 'middle',
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            }
        }
        setStateOptions(opts)
        setStateSeries([])
    }

    async function dashboardByWorkflow(created, statusId, dontReload) {
        let response = await dashboardReportApi.getByWorkflow(created, statusId);
        let result = check(response)

        if (result.status) {
            let data = response.data
            let opts = options(data, 'Flujos de trabajo', 'workflow', 'name', null, data)
            let series = data.map((el) => el.count)

            setFlowData(data)
            setFlowOptions(opts)
            setFlowSeries(series)

            let isEmptyChart = false
            if (chartRef?.current !== null) {
                isEmptyChart = Object.keys(chartRef?.current?.chart)?.length > 0
            }

            if (!selected.workflow && isEmptyChart && dontReload === undefined) {
                let position = opts.labels.indexOf(selected.workflowSelect?.name || 'Permisos de trabajo')

                if (chartRef.current?.chart !== undefined && chartRef.current?.chart !== null && position !== undefined) {
                  chartRef.current?.chart?.toggleDataPointSelection(position)
                  chartRef.current?.chart?.updateSeries(series, false)
                }
            }
        }
    }

    async function dashboardByPlotPlan(plotPlanId, workflowId, created, statusId) {
        setLoadingPlotPlan(true)
        let plotPlan = (!plotPlanId || plotPlanId <= 0) ? 1 : plotPlanId
        let response = await dashboardReportApi.getByPlotPlan(plotPlan, workflowId, created, statusId);
        let result = check(response)

        if (result.status) {
            setPlotPlanList(response.data)
        }
        setLoadingPlotPlan(false)
    }

    async function dashboardByState(plotPlanId, workflowId, created) {
        if (!workflowId) return
        let plotPlan = !plotPlanId ? null : plotPlanId
        let response = await dashboardReportApi.getByStatus({ plotPlanId: plotPlan, workflowId: workflowId, created: created });
        let result = check(response)

        if (result.status) {
            if (response.data?.length === 0) {
                initStateSeries([t('common.empty.noInstances')])
            }
            else {
                setStateOptions(options(response.data, 'Estados', 'status', 'title'))
                let series = []
                response.data.map(obj => { series.push({ x: obj.status?.title, y: obj.count }) })
                setStateSeries([{ data: series }])
            }
        }
    }

    async function dashboardByCreated(plotPlanId, workflowId, statusId) {
        let plotPlan = !plotPlanId ? null : plotPlanId
        let response = await dashboardReportApi.getByCreated(plotPlan, workflowId, statusId);
        let result = check(response)

        if (result.status) {
            setOpenInstanceOptions(options(response.data, 'En estado abierto', 'created', null))
            let series = []
            response.data.map(obj => { series.push({ x: obj.created, y: obj.count }) })
            setOpenInstanceSeries([{ data: series }])
        }
    }

    async function listWorkflow() {
        let response = await workflowApi.list();
        let result = check(response)

        if (result.status) {
            let selectedWorkflow = null
            let defaultWorkflow = response.data.find((el) => el.default)
            if (defaultWorkflow?.id) selectedWorkflow = defaultWorkflow
            // else selectedWorkflow = response.data.find((el) => el.id === 1)
            else selectedWorkflow = response.data[0]


            setWorkflows(response.data)
            setSelected({ ...selected, workflowSelect: selectedWorkflow })
        }
    }

    async function downloadReport() {
        setLoading(true)
        let response = await dashboardReportApi.downloadReport(selected.plotPlan, selected.workflow, selected.status, selected.created);
        let result = check(response)
        setLoading(false)

        if (result.status) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'workpermits.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    async function getTable() {
        let response = await dashboardReportApi.getTable(selected.plotPlan, selected.workflow, selected.status, selected.created);
        let result = check(response)

        if (result.status) {
            setTableList(response.data)
        }
    }

    function handleChange(name, value) {
        setSelected({ ...selected, [name]: value })
        selected[name] = value
    }

    async function callFilters(plotPlanId, workflowId, created, statusId) {
        let workflow = selected.workflowSelect?.id || workflowId
        await dashboardByWorkflow(created, statusId, true)
        await dashboardByPlotPlan(plotPlanId, workflow, created, statusId)
        await dashboardByCreated(plotPlanId, workflow, statusId)

        if (!workflow) initStateSeries()
        else await dashboardByState(plotPlanId, workflow, created)
    }

    async function handleSelected(title, key, id, works) {
        if (title === 'Estados') {
            let dataId = id?.length > 0 ? id[0]?.[key]?.id : null
            await handleSelectedStatus(dataId)
        }
        if (title === 'En estado abierto') {
            await handleSelectedCreated(id, key)
        }
        if (title === 'Flujos de trabajo') {
            let dataId = id?.length > 0 ? id[0]?.[key]?.id : null
            await handleSelectedWorkflow(dataId, works)
        }
    }

    function handleChangeSelect(value) {
        let isClear = value === selected.workflow
        let detail = workflows.filter((el) => el.id === value)?.[0]
        if (!chartRef.current?.props?.options?.labels?.some((el) => el === detail?.name)) return
        handleChange('workflow', isClear ? null : value)
        handleChange('workflowSelect', isClear ? [] : detail)
        selectGraphic(detail)
    }

    async function handleSelectedWorkflow(dataId, works) {
        let workflow = dataId === selected.workflow ? null : dataId
        let detail = []

        if (works.length > 0) {
            detail = works?.filter((el) => el.workflow.id === workflow)?.[0]?.workflow
        }
        else {
            detail = workflows?.filter((el) => el.id === workflow)?.[0]
        }

        handleChange('workflow', workflow)
        handleChange('workflowSelect', detail || [])
        handleChange('plotPlan', null)
        if (flowOptions.labels?.length > 0) selectGraphic(detail)

        await callFilters(selected.plotPlan, workflow, selected.created, selected.status)
    }

    function selectGraphic(data) {
        let position = flowOptions.labels.indexOf(data?.name)
        if (chartRef.current?.chart !== null && chartRef.current?.chart !== undefined) {
          chartRef.current?.chart?.toggleDataPointSelection(position)
          chartRef.current?.chart?.updateSeries(flowSeries, false)
        }
    }

    async function handleSelectedCreated(data, key) {
        let dataId = data?.length > 0 ? data[0]?.[key] : null
        let created = dataId === selected.created ? null : dataId
        handleChange('created', created)
        handleChange('plotPlan', null)
        await callFilters(selected.plotPlan, selected.workflow, created, selected.status)
    }

    async function handleSelectedStatus(dataId) {
        let status = dataId === selected.status ? null : dataId
        handleChange('status', status)
        handleChange('plotPlan', null)
        await callFilters(selected.plotPlan, selected.workflow, selected.created, status)
    }

    const options = (labels, title, key, secondKey, isArray, works) => {
        let opts = {
            labels: isArray ? labels : labels?.map((el) => el[key]?.[secondKey] || el[key]),
            title: {
                text: title,
                align: 'center',
                style: {
                    fontSize: '0px'
                }
            },
            legend: {
                show: true
            },
            colors: [colors.main, colors.sectionTitleColor, colors.orange, colors.red, colors.purple, colors.green, colors.blue],
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    let value = val
                    if (title !== 'En estado abierto' && title !== 'Estados') {
                        const labels = opt?.w?.globals?.labels?.[opt.seriesIndex] ?? ""
                        value = opt?.w?.globals?.series?.[opt.seriesIndex] ?? ""
                        return [labels, value]
                    }
                    else {
                        return [value]
                    }
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            chart: {
                selection: {
                    enabled: true,
                },
                id: title,
                fontFamily: 'Montserrat, sans-serif',
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: ( _, __, config) => {
                        let conf = config.w?.config
                        let index = config.dataPointIndex
                        let id = []

                        if (secondKey) id = labels?.filter((el) => el[key]?.[secondKey] === conf?.labels?.[index])
                        else id = labels?.filter((el) => el[key] === conf?.labels?.[index])
                        handleSelected(conf?.title?.text, key, id, works)
                    }
                },
            },
        }

        if (title === 'Flujos de trabajo') {
            opts = {
                ...opts,
                legend: {
                    show: true,
                    position: 'right',
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'middle',
                    style: {
                        colors: ['#fff'],
                        fontSize: '12px',
                    },
                    formatter: function (_, opt) {
                        let value = opt?.w?.globals?.series?.[opt.seriesIndex] ?? ""
                        return [value]
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                value: {
                                    show: true,
                                    fontSize: '16px',
                                },
                                name: {
                                    show: true,
                                    fontSize: '16px',
                                },
                                total: {
                                    show: true,
                                    label: '',
                                    fontFamily: 'Montserrat, sans-serif',
                                    formatter: function (chart) {
                                        let position = chart?.globals?.selectedDataPoints?.[0]?.[0]
                                        if (chart?.config?.labels?.[position] !== undefined && chart?.config?.series?.[position] !== undefined) {
                                            return [chart.config.labels[position], chart.config.series[position]]
                                        }
                                        else return ''
                                    }
                                }
                            }
                        }
                    }
                },
                responsive: [{
                    breakpoint: 1440,
                    options: {
                        chart: {
                            // width: 400
                        },
                        legend: {
                            position: 'bottom'
                        },
                        value: {
                            show: true,
                            fontSize: '13px',
                        },
                    }
                }]
            }
        }

        return opts
    }

    // Table
    const headers = [
        {
            // id: 'code',
            id: 'codeDash',
            label: 'dashboard.table.id',
            isTrans: true,
            center: true,
            disablePadding: true,
            sortArrow: true,
            type: 'link',
            onClick: linkComponent
        },
        {
            id: 'title',
            label: 'dashboard.table.title',
            center: false,
            disablePadding: true,
            sortArrow: true,
            isRender: true,
            customSort: "title",
        },
        {
            id: 'workflow',
            label: 'dashboard.table.workflow',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'object',
            keys: ['name'],
            customSort: "workflow?.name",
        },
        {
            id: 'status',
            label: 'dashboard.table.status',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'custom',
            customSort: "workflowStatus?.title",
        },
        {
            id: 'validityFrom',
            id2: 'validityTo',
            label: 'dashboard.table.validity',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'validity',
            customSort: "validityFrom",
        },
        {
            id: 'activeTime',
            label: 'dashboard.table.activeTime',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'activeTime',
            customSort: "instanceTime?.activeTime",
        },
        {
            id: 'suspendedTime',
            label: 'dashboard.table.suspendedTime',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'suspendedTime',
            customSort: "instanceTime?.suspendedTime",
        },
        {
            id: 'plotPlan',
            label: 'dashboard.table.plotPlan',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'object',
            keys: ['name'],
            customSort: "plotPlan?.name",
        },
        {
            id: 'createdAt',
            label: 'dashboard.table.createdAt',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'shortDate',
            customSort: "createdAt",
        },
        {
            id: 'updatedAt',
            label: 'dashboard.table.updatedAt',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'shortDate',
            customSort: "updatedAt",
        },
    ];

    function linkComponent(row) {
        navigate(Router.appWorkflowDetail.replace(':workflowId', row.workflow?.id).replace(':id', row.id),
            { state: { row: row, workflow: row.workflow, from: Router.appDashboardReport } }
        )
    }

    function statusCellComponent(workflowStatus) {
        if (workflowStatus) return <StatusCell workflowStatus={workflowStatus} />
        return <div></div>
    }

    const validityTooltip = ( fromDate, toDate ) => {
        let validity = '';

        if (fromDate && toDate !== null) {

            validity = `${t('common.from')} ${format({ date: fromDate, formatWithoutHour: true })}
              ${t('common.to')} ${format({ date: toDate, formatWithoutHour: true })} 
            `
        }
        return validity
    }

    return (
        <Container open={props.open} mb={6}>
            <ContainerTitle>
                <PageTitle title={t('dashboard.title')} icon={<Assessment />} />
            </ContainerTitle>

            <ContainerActions>
                <ContainerLeft>
                    <SelectInput
                        label={t('dashboard.select')}
                        name='workflowSelect'
                        onChange={e => handleChangeSelect(e.id)}
                        value={selected.workflowSelect}
                        keyValue="name"
                        options={workflows}
                        size="small"
                        width="100%"
                    />
                </ContainerLeft>

                <ContainerRight>
                    {/* <CustomButton
                        variant="contained"
                        endIcon={<CloudDownloadOutlined />}
                        sx={{ 
                            height: '100%', 
                            padding: '0 20px'
                        }}
                        aria-label="download"
                        onClick={downloadReport}
                    >
                        {t('dashboard.download')}
                    </CustomButton> */}
                    <ContainerButtonDownload>
                        <Button
                            variant="contained"
                            endIcon={<CloudDownloadOutlined />}
                            sx={{
                                height: "100%",
                                width: "100%",
                                padding: "0 20px",
                                backgroundColor: colors.buttonActionColor,
                                color: colors.white,
                                "&:hover": {
                                    backgroundColor: colors.buttonActionColor,
                                    opacity: 0.9,
                                    color: colors.white
                                }
                            }}
                            aria-label="download"
                            onClick={downloadReport}
                        >
                            {t('dashboard.download')}
                        </Button>
                    </ContainerButtonDownload>

                </ContainerRight>
            </ContainerActions>

            <ContainerGraphics>
                <ContainerGroup>
                    <ContainerCard>
                        <GridCard>
                            <Title>&nbsp;</Title>
                            <Title>{t('dashboard.graphics.workflow.title')}</Title>
                            {flowSeries.length > 0 ?
                                <CardGraphic
                                    chartRef={chartRef}
                                    type="donut"
                                    series={flowSeries}
                                    options={flowOptions}
                                />
                                : <div style={{ marginTop: 40 }}> {t('common.empty.noData')} </div>}
                        </GridCard>
                    </ContainerCard>
                    <ContainerCard>
                        <GridCard>
                            <Tooltip
                                title={
                                    <Ul>
                                        <Li>{t('dashboard.graphics.created.tooltip1')}</Li>
                                        <Li>{t('dashboard.graphics.created.tooltip2')}</Li>
                                    </Ul>
                                }
                            >
                                <Info sx={{ color: colors.main }}></Info>
                            </Tooltip>
                            <Title>{t('dashboard.graphics.created.title')}</Title>
                            <CardGraphic
                                type="bar"
                                series={openInstanceSeries}
                                options={openInstanceOptions}
                            />
                        </GridCard>
                    </ContainerCard>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerCard>
                        <GridCard>
                            <Tooltip title={t('dashboard.graphics.status.tooltip1')}>
                                <Info sx={{ color: colors.main }}></Info>
                            </Tooltip>
                            <Title>{t('dashboard.graphics.status.title')}</Title>
                            <CardGraphic
                                type="bar"
                                series={stateSeries}
                                options={stateOptions}
                            />
                        </GridCard>
                    </ContainerCard>
                    <ContainerCard>
                        <GridCard>
                            <HtmlTooltip
                                title={
                                    <Ul>
                                        <Li>{t('dashboard.graphics.plotPlan.tooltip1')}</Li>
                                        <Li>{t('dashboard.graphics.plotPlan.tooltip2')}</Li>
                                    </Ul>
                                }
                            >
                                <Info sx={{ color: colors.main }}></Info>
                            </HtmlTooltip>
                            <Title>{t('dashboard.graphics.plotPlan.title')}</Title>
                            {!loadingPlotPlan ?
                                <div style={{ maxHeight: 300, width: '100%', flexGrow: 1, overflowY: 'auto' }}>
                                    <NestedApiCall plotPlans={plotPlanList} selected={selected} setSelected={setSelected} />
                                </div>
                                :
                                <div style={{ marginTop: 40 }}>
                                    <LoadingProgress full="true" />
                                </div>
                            }
                        </GridCard>
                    </ContainerCard>
                </ContainerGroup>
            </ContainerGraphics>

            <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item xs={12} mt={2}>
                    <TableModel
                        headers={headers}
                        filteredRequest={tableList}
                        rows={tableList}
                        hideFilterDate={true}
                        hideToolbar={true}
                        defaultRowsPerPage={25}
                        custom={statusCellComponent}
                        validityTooltip={validityTooltip}
                        defaultSort={'createdAt'}
                    />
                </Grid>
            </Grid>

            <BackdropLoading open={loading} />
        </Container>
    )
}

