import React, { useEffect, useState } from "react";

// Components
import { toast } from "react-toastify";
import { Modal, Button, Tab, Box } from "@mui/material";

// Custom components
import { PageTitle } from "../../components/PageTitle";
import { ShowDialog } from "../../components/ShowDialog";
import { BackdropLoading } from "../../components/BackdropLoading";
import { InstanceStateModalCard } from "../../components/atoms/modalCards/InstanceStateModalCard";
import { PlotPlanView } from "../../components/PlotPlanView";
import { TabPanel } from "../../components/atoms/tabPanel";
import { SearchBarWorkflows } from "../../components/atoms/searchBars/SearchBarWorkflows";
import TableWorkflow from "../../components/atoms/tables/TableWorkflow";

// Services
import { WorkflowDataApi } from "../../services/WorkflowDataApi";
import { WorkflowStatusApi } from "../../services/WorkflowStatusApi";
import { WorkflowApi } from "../../services/WorkflowApi";
import { PlantApi } from "../../services/PlantApi";
import { useNavigate, useLocation } from "react-router-dom";
import Router from "../../router";

// Styles
import { BoxModal, Container, ContainerBarSearch, ContainerButtonDownload, SectionPageLeft, SectionPageTitle } from "../styles";
import { ContainersButtonsGroup, CustomBadge, CustomTabs } from './styles';
import { ColorsContext } from "../../Context/ColorsContext";

// Icons
import { PendingActions, CloudDownloadOutlined } from "@mui/icons-material";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { ContentCopy, Delete } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Utils
import { CheckResponse } from "../../utils/checkResponse";
import { redirectToAction, returnInstanceNotifications } from "../../utils/commonFunctions";
import { FormatDate } from "../../utils/formatDate";
import { useTranslation } from 'react-i18next';
import { ExportExcel } from "../../utils/exportExcel";

// Hooks
import { InstanceNotificationsModal } from "../../components/atoms/modalCards/InstanceNotificationsModal";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const MyWork = (props) => {
  const { t } = useTranslation();
  const { colors, setFlagNotification } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const workflowDataApi = new WorkflowDataApi();
  const workflowStatusApi = new WorkflowStatusApi();
  const workflowApi = new WorkflowApi();
  const plantApi = new PlantApi();
  const { check } = new CheckResponse();
  const { format } = new FormatDate();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterPlants, setFilterPlants] = useState([]);
  const [arrayCombined, setArrayCombined] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]);

  const [filteredRequest, setFilteredRequest] = useState([]);
  const [maintainFilter, setMaintainFilter] = useState([]);
  const [searchText, setSearchText] = useState('')
  const [firstLoading, setFirstLoading] = useState({
    called: false,
    loading: false
  })
  const [dialog, setDialog] = useState({
    status: false,
    title: "",
    message: "",
    type: null,
  });

  // Instance state
  const [openInstanceStateModal, setOpenInstanceStateModal] = useState({
    status: false,
    action: null,
    isDelete: false,
  });

  // Plot Plan modal
  const [plotPlanModal, setPlotPlanModal] = useState(false);
  const [plotPlanData, setPlotPlanData] = useState({});

  // Tabs
  const [workflowTabs, setWorkflowTabs] = useState([]);
	const [tab, setTab] = useState(0)
	const [selectedTab, setSelectedTab] = useState({})
	const [reloadFilters, setReloadFilters] = useState(false)
  const [headers, setHeaders] = useState([]);

  // Workflow
  const [workflow, setWorkflow] = useState();
  const [relations, setRelations] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [notificationsModal, setNotificationsModal] = useState({
    status: false,
    notifications: [],
    instance: {}
  });

  useEffect(() => {
    if (location.pathname === "/") navigate(Router.appMyWork);
    async function call() {
      setLoading(true)
      await loadListPlants();
      setLoading(false)
    }
    call();
  }, []);

  useEffect(() => {
    async function call() {
      if (!firstLoading.called) {
        setFirstLoading({called: false, loading: true})
      }
      if (props.updateWorkflow !== false) {
        await loadData();
        setFirstLoading({called: true, loading: false})
      }
    }
    call();
  }, [props.updateWorkflow]);

  const returnHeaders = (data) => {

    let heads = [
      {
        id: "code",
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: "workflow.table.id",
        sticky: true,
        direction: 'left'
      },
      {
        id: "workflowStatus",
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: "workflow.table.status",
        format: (value) => value.workflowStatus?.title,
        customSort: "workflowStatus?.title",
      },
      {
        id: "hasTitle",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => value.title,
        isOptional: true,
        label: "workflow.table.title",
        customSort: "title",
      },
      {
        id: "plant",
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: "workflow.table.plant",
        format: (value) => value.plant?.name,
        customSort: "plant?.name",
      },
      {
        id: "hasValidity",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => {
          if (!value.validityFrom && !value.validityTo) {
            return null;
          }
          return (
            format({ date: value.validityFrom, formatWithoutHour: true }) +
            " - " +
            format({ date: value.validityTo, formatWithoutHour: true })
          );
        },
        isOptional: true,
        label: "workflow.table.validity",
        customSort: 'validityFrom',
      },
      {
        id: "updatedAt",
        type: "date",
        center: false,
        disablePadding: true,
        format: (value) => format( { date: value.updatedAt, format: true }),
        sortArrow: true,
        label: "workflow.table.lastUpdate",
      },
      {
        id: "",
        center: false,
        disablePadding: true,
        sortArrow: false,
        label: "common.actions.title",
        hideColumn: true,
        sticky: true,
        direction: 'right',
      },
    ];

    data[0]?.formColumn?.map((obj) => {
      let newObj = {
        id: obj.title,
        center: true,
        disablePadding: true,
        sortArrow: true,
        format: (value, index) => (value.formColumn[index] ? "Si" : "No"),
        label: obj.title,
        isOptional: true,
        addHeader: true
      }
      heads.splice(heads.length - 1, 0, newObj)
    })

    if (ability.can("PlotPlanList") && ability.can("GetPlotPlan")) {
      heads.splice(heads.length - 1, 0, addPlotPlansColumn())
    }

    setHeaders(heads)
  }

  // data SpeedDial for dimension mobile
  const addDataSpeedDial = (row, workflow) => {
    const options = [
      {
        name: 'process',
        tooltip: t("common.actions.process"),
        onClick: () => handleOpenStatus(row),
        icon: <SlowMotionVideoIcon/>,
      },
      {
        name: 'duplicate',
        tooltip: t("common.actions.duplicate"),
        onClick: () => handleOpenDuplicate(row),
        icon: <ContentCopy/>,
        show: ability.can("CloneWorkflowData") ? true : false,
      },
      {
        name: 'cancel',
        tooltip: t("common.actions.cancel"),
        onClick: () => handleOpenDelete(row),
        icon: <Delete/>,
        show: ability.can("DeleteWorkflowData"),
        disabled: row.workflowStatus?.statusId === 10002 ||
        row.workflowStatus?.statusId === 10003 ||
        row.workflowData?.canCancel === false ||
        row?.canCancel === false,
      },
      {
        name: 'approve',
        tooltip: t('common.approve'),
        onClick: () => handleOpenApprove(true, row),
        icon: <CheckCircleIcon/>,
        show: ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials"),
        disabled: !row.isApprove,
      },
      {
        name:'reject',
        tooltip: t('common.reject'),
        onClick: () => handleOpenApprove(false, row),
        icon: <CancelIcon/>,
        show: ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials"),
        disabled: !row.isApprove,
      },
    ];

    return options
};

  function addPlotPlansColumn() {
    return {
      id: "hasPlotPlan",
      center: true,
      disablePadding: true,
      sortArrow: false,
      isOptional: true,
      format: (value) => value.plotPlan?.name,
      label: "workflow.table.plot",
    },
    {
      id: "hasPlotPlanNotRequired",
      center: true,
      disablePadding: true,
      sortArrow: false,
      isOptional: true,
      format: (value) => (value.workflowData?.plotPlans?.map((item) => item?.name) || []).join(', '),
      label: "workflow.table.plot",
    }
  }

  const addDinamicColumns = ( dinacWorkflow  ) => {

    const addNewColumns = dinacWorkflow.map((el, index) => ({
      id: el.title.trim() + index,
      label: el.title,
      center: true,
      disablePadding: true,
      sortArrow: true,
      customSort: (item) => item.formColumn[index]?.value
    }))

    if(addNewColumns.length > 0) {
      headers.splice( -3, 0, ...addNewColumns )
    }
  }

  async function readNotification(id) {
    await workflowDataApi.updateNotification(id);
  }

  const handleDownloadExcel = () => {
    const fileName = workflow?.name || t("workflow.ValidationFile");
    const dinamicColumns = workflow?.formColumns || null;


    const headerWorkflows = workflow?.relations?.map((item, index) => ({
      id: item.name,
      label: item.name,
      index: index,
      isRelation: true,
    }));

    if (headerWorkflows.length > 0) {
      headers.push(...headerWorkflows);
    }

    const newColumns = dinamicColumns?.map((item, index) => ({
      id: item.title,
      label: item.title,
      index: index,
      format: (item) => item?.formColumn[index]?.value
    }))

    if(newColumns?.length > 0) {
      headers.push(...newColumns);
    }

    let headerWorkflows2 = workflow.relations?.map((el) => el.name);

    const data = filteredRequest.map((item) => {
      let newArray = headerWorkflows2.map((key, index) => ({
        [key]: item.relations[index],
      }));

      item = {
        ...item,
        ...newArray.reduce((result, obj) => ({ ...result, ...obj }), {}),
      };

      const rowData = {};
      headers.map((headCell) => {

        if(headCell.format){
          if (headCell.isOptional && !workflow[headCell.id]) return;
          else item[headCell.id] = headCell.format(item, item.index);
        }

        if(!headCell.hideColumn) {
          const nestedProps = headCell.id.split(".");

          // const nestedProps = headerCell.id.split('.');
          //?? Acceder a las propiedades anidadas utilizando reduce
          const value = nestedProps.reduce(
            (obj, prop) => (obj ? obj[prop] : undefined),
            item
          );

          rowData[t(headCell.label)] = value;
        }

      })

      return rowData;

    });

    ExportExcel({ apiData: data, fileName: fileName });
  };

  async function loadData() {
    setLoading(true)
		let data = []
		let response = await workflowDataApi.notification(true, false)

    if (response?.status === 200) {
      data = response.data
      data?.map((row) => {
        if (
          !!(
            row.workflowStatus?.statusId === 10002 ||
            row.workflowStatus?.statusId === 10003
          )
        )
        row.disabledDelete = true;
        row.plotPlanId = row.workflowData?.plotPlan?.id;
        row.workflowName = row.workflow?.name;
        row.code = row.workflowData?.code;
        row.title = row.workflowData?.title;
        row.updatedAt = row.workflowData?.updatedAt
        row.linkName = row.workflowData?.code || row.workflowData?.id;
      });
    }

    setLoading(false)
    await getDataByWorkflow(data)
  }

  const loadListPlants = async () => {
    if (ability.can("PlanUserList")) {
      let response = await plantApi.getByUser();
      if (response.status === 200) {
        setFilterPlants(response.data)
      }
    }
  };

  const loadWorkflowStatusSelect = async (id) => {
    if (ability.can("WorkflowStatusList")) {
      const response = await workflowStatusApi.workflowStatusSelect(id);
      if (response.status === 200) {
        setArrayCombined(response.data);
      }
    }
  };



  async function loadWorkflow(id) {
    let response = await workflowApi.detail(id);
    if (response.status === 200) {
      let data = response.data;
      data?.formColumns?.reduce((acc, header) => {
        acc[header.title] = true
        return acc
      }, data)
      setWorkflow(data);
      setRelations(data?.relations);
      setFormColumns(data?.formColumns);
      addDinamicColumns(data?.formColumns);
    }
  }

  async function getDataByWorkflow(data) {
    // Utilizamos reduce para agrupar registros por workflowId
    const groupedData = data.reduce((acc, obj) => {
      const workflowId = obj.workflow.id;

      if (obj.workflow?.isActive === true) {
        if (!acc[workflowId]) {
          acc[workflowId] = {
            ...obj.workflow,
            data: [],
            count: 0,
          };
        }

        acc[workflowId].data.push(obj);
        acc[workflowId].count += 1;
      }

      return acc;
    }, {});

    // Convertimos el objeto agrupado nuevamente en un array de objetos
    const grouped = Object.values(groupedData);

    grouped.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
    if (grouped?.length > 0) {
      let selectedWorkflow = tab ? grouped[tab] : grouped[0]
      setWorkflowTabs(grouped)
      handleChangeTab(selectedWorkflow, tab || 0)
    }
    else {
      setWorkflowTabs([])
      setData([]);
    }
  }

  async function handleClearFilters() {
    setData(workflowTabs[tab].data)
    setFilteredRequest(workflowTabs[tab].data)
  }

	async function callNotificationAction(id) {
		const response = await workflowDataApi.getDetailNotification(id, true);
		let result = check(response);

		if (result.status) {
			return response.data;
    } else {
      toast.error(() => (
        <div>
					{t('myWork.messages.actionInstance.error')}<br />
          {result.errors}
        </div>
      ));
			return {};
    }
	}

  async function handleOpenDuplicate(row) {
		let rowDetail = await callNotificationAction(row.id);
    setDialog({ status: false, row: rowDetail })
    showInstanceState(handleDuplicate, false, rowDetail, true)
  }

  async function handleDuplicate(users) {
    let sendData = {
      workflowDataId: users.extraData.workflowDataId,
      users: users.users
    }
    let response = await workflowDataApi.clone(sendData);
    let result = check(response)

    if (result.status) {
      handleCloseInstance()

      setDialog({
        status: true,
        title: t('workflow.messages.flowDuplicated.title'),
        message: t('workflow.messages.flowDuplicated.message', { workflow: users.extraData?.workflow?.name || '' })
      })
    }
    else {
      toast.error(() =>
        <div>{t('workflow.messages.flowDuplicated.error')}<br />
          {result.errors}
        </div>
      );
    }
  }

  async function handleDelete(users) {
    setLoading(true);

    let data = {
      id: dialog.row?.workflowDataId,
      notificationId: dialog.row?.id,
      statusId: dialog.row?.workflowData?.workflowStatus?.statusId,
      users: users.users,
			cancelObservations: users.cancelObservations
    };
    let response = await workflowDataApi.delete(data);
    let result = check(response);

    if (result.status) {
      handleCloseInstance();
      setDialog({
        status: true,
        title: t('myWork.messages.deletedInstance.title'),
        message: t('myWork.messages.deletedInstance.message'),
        type: null,
      });
    } else {
      toast.error(() => (
        <div>
          {t('myWork.messages.deletedInstance.error')}
          <br />
          {result.errors}
        </div>
      ));
    }

    setLoading(false);
  }

  async function handleCloseDialog(value) {
    if (value) {
      setDialog({ ...dialog, status: false });
      showInstanceState(handleDelete, true);
    } else {
      setDialog({ status: false });
    }
  }

  async function openPlotPlan(row) {
    setPlotPlanData(row)
    setPlotPlanModal(true)
  }

  async function showInstanceState(action, isDelete, data, isDuplicate, isFashApprove) {
    setOpenInstanceStateModal({
      status: true,
      action: action,
      data: data,
      isDelete: isDelete,
      isDuplicate: isDuplicate,
      isFashApprove: isFashApprove,
    });
  }

  async function handleCloseInstance() {
    setOpenInstanceStateModal({ status: false });
    setDialog({ status: false });
  }

  function handleCloseNotifications() {
    setNotificationsModal({ status: false, notifications: [], instance: {} });
  }

  async function handleChangeTab(row, value, isChangeTab) {
    if (isChangeTab) {
      setLoading(true)
    }

    setTab(value);
    setSelectedTab(row);
    if (workflow?.id !== row.id) {
      await loadWorkflow(row.id)
    }
    await loadWorkflowStatusSelect(row.id);
    let data = compareNotificationWorkflowData(row.data)

    let mergedData = mergeDynamicColumns(data);

    setData(mergedData);
    if (selectedPlants.length > 0 || selectedTags.length > 0 || searchText) {
      setReloadFilters(!reloadFilters)
    }
    else {
      setFilteredRequest(mergedData);
      setMaintainFilter(mergedData);
      returnHeaders(mergedData);
    }

    setLoading(false)
  }

  function mergeDynamicColumns(data) {
    const newData = data.map(item => {
      const newItem = {...item};

      item.formColumn?.reduce((acc, formColumn) => {
        acc[formColumn.title] = formColumn.value;
        return acc;
      }, newItem);

      return newItem;
    });

    return newData;
  }

  function compareNotificationWorkflowData(notifications) {
    notifications.map((notification) => {

      notification.formColumn = notification?.workflowData?.formColumn
      notification.relations = notification?.workflowData?.relations
      notification.plant = notification?.workflowData?.plant
      notification.validityFrom = notification?.workflowData?.validityFrom
      notification.validityTo = notification?.workflowData?.validityTo
      notification.validityTo = notification?.workflowData?.validityTo
      notification.plotPlan = notification?.workflowData?.plotPlan
      notification.updatedAt = notification?.workflowData?.updatedAt
      notification.title = notification?.workflowData?.title
      notification.notificationId = notification.id
      notification.isApprove = notification.type === 'check_form' || notification.type === 'check_form_credentials'

    })
    return notifications
  }

  async function handleOpenStatus(row) {
    setLoading(true);
    await readNotification(row.id);
    // Cambia de estado cuando se le ha dado procesar a una instancia y poder actualizar el llamado de notificaciones en la navbar
    setFlagNotification(prevState => !prevState)
    let notifications = await returnInstanceNotifications(row, true);
    setLoading(false);
    if (notifications?.length > 1) {
      setNotificationsModal({status: true, notifications: notifications, instance: row})
    }
    else {
      await redirectToAction(row.workflowData, notifications, navigate, location)
    }
  }

  async function handleOpenApprove(value, row) {
		let rowDetail = await callNotificationAction(row.id);
    if (row.type === 'check_form' || row.type === 'check_form_credentials') {
      let rowData = {...rowDetail, showApproveForm: true, approveInstance: value}
      setDialog({ row: rowData });
      showInstanceState(handleApprove, false, rowData, false, true);
    }
  }

  async function handleApprove(formData) {
    let data = {
      id: formData.extraData?.workflowDataId,
			approved: formData.extraData?.approveInstance,
			notificationId: formData.extraData?.id,
			users: formData.users,
			observations: formData.observations,
      cancellationReasonId: formData.cancellationReasonId
		}

    setLoading(true);
    let response = null;
    if (formData.extraData?.type === "check_form_credentials") {
      data = { ...data, credentials: formData.credentials };
      response = await workflowDataApi.checkFormCredentials(data);
    } else {
      response = await workflowDataApi.approveForm(data);
    }

    let result = check(response)

    if (result.status) {
      handleCloseInstance();
      setDialog({
        title: t("forms.dynamicForm.messages.approveForm.title"),
        message: t("forms.dynamicForm.messages.approveForm.message", {
          value: formData.extraData?.approveInstance
            ? "forms.dynamicForm.approved"
            : "forms.dynamicForm.rejected",
        }),
        status: true
      });
    } else {
      toast.error(() => (
        <div>
          {t("forms.dynamicForm.messages.approveForm.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
    setLoading(false);
  }

  async function handleOpenDelete(row) {
		let rowDetail = await callNotificationAction(row.id);
    setDialog({
      title: t("workflow.messages.deleteFlow.confirm"),
      message: t("workflow.messages.deleteFlow.confirmMessage", {
        workflow: row.code,
      }),
      type: "confirmation",
      row: rowDetail,
      status: true
    });
  }

  const validityTooltip = ( fromDate, toDate ) => {
    let validity = '';

    if( fromDate && toDate !== null) {

      validity = `${t('common.from')} ${format({ date: fromDate, formatWithoutHour: true })}
      ${t('common.to')} ${format({ date: toDate, formatWithoutHour: true })}
      `
    }

    return validity
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle title={t('myWork.title')} icon={<PendingActions />} />
          <ContainerBarSearch>
            <SearchBarWorkflows
              clearFilter={tab}
              rows={data}
              setFilteredRequest={setFilteredRequest}
              maintainFilter={maintainFilter}
              setSearchText={setSearchText}
              filterText={t('common.searchBars.workflow')}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup>
          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{
                height: '100%',
                width: '100%',
                padding: "0 20px",
                backgroundColor: colors.buttonActionColor,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.buttonActionColor,
                  opacity: 0.9,
                  color: colors.white
                }
              }}
              aria-label="download"
              onClick={handleDownloadExcel}
            >
              {t('common.download')}
            </Button>
          </ContainerButtonDownload>
        </ContainersButtonsGroup>

      </SectionPageTitle>

      <Box sx={{ marginTop: 2, borderBottom: 1, borderColor: 'divider' }}>
        <CustomTabs
          value={tab}
          onChange={handleChangeTab}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="scrollable"
          TabIndicatorProps={{style: {backgroundColor: colors.buttonActionColor, color: colors.buttonActionColor }}}
        >
          {workflowTabs.map((row, index) => (
            <CustomBadge
              key={row.id}
              badgeContent={row.count}
              color="primary"
              onClick={() => handleChangeTab(row, index, true)}
            >
              <Tab
                value={index}
                label={row.name}
              />
            </CustomBadge>
          ))}
        </CustomTabs>
      </Box>

      {workflowTabs.map((row, index) => (
        <TabPanel key={row.name} value={tab} index={index}>
          <TableWorkflow
            arrayCombined={arrayCombined}
            setFilteredRequest={setFilteredRequest}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            filterPlants={filterPlants}
            filteredRequest={filteredRequest}
            loading={loading}
            rows={data}
            handleClearDate={handleClearFilters}
            openPlotPlan={openPlotPlan}
            handleOpenStatus={handleOpenStatus}
            handleApprove={handleOpenApprove}
            handleOpenDuplicate={handleOpenDuplicate}
            handleOpenDelete={handleOpenDelete}
            workflow={workflow}
            relations={relations}
            formColumns={formColumns}
            headers={headers}
            setMaintainFilter={setMaintainFilter}
            hideDatesFilter={true}
            reloadFilters={reloadFilters}
            search={searchText}
            defaultSort={'updatedAt'}
            validityTooltip={validityTooltip}
            addDataSpeedDial={addDataSpeedDial}
            rowsCount={filteredRequest.length}
            myWork={true}
            section={'myWork'}
            />
        </TabPanel>
      ))}

      <BackdropLoading open={loading} />
      <BackdropLoading open={firstLoading.loading} />
      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />

      <Modal open={openInstanceStateModal.status}>
        <BoxModal>
          <InstanceStateModalCard
            data={dialog?.row || workflow}
            extraData={openInstanceStateModal.data}
            isDelete={openInstanceStateModal.isDelete}
            isDuplicate={openInstanceStateModal.isDuplicate}
            isFashApprove={openInstanceStateModal.isFashApprove}
            handleSave={openInstanceStateModal.action}
            handleClose={handleCloseInstance}
            setOpenInstanceStateModal={setOpenInstanceStateModal}
          />
        </BoxModal>
      </Modal>

      <Modal open={notificationsModal.status}>
        <BoxModal>
          <InstanceNotificationsModal
            notifications={notificationsModal.notifications}
            instance={notificationsModal.instance}
            handleClose={handleCloseNotifications}
          />
        </BoxModal>
      </Modal>

      <PlotPlanView
        data={plotPlanData}
        openDialog={plotPlanModal}
        handleCloseDialog={() => setPlotPlanModal(false)}
      />

    </Container>
  );
};
