import * as React from "react";
import { useState, useEffect } from "react";
import { InputAdornment, ListSubheader, TextField, Tooltip, FormControlLabel, } from "@mui/material";

// Component Date
import 'dayjs/locale/es';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TuneIcon from '@mui/icons-material/Tune';

// Multi Select
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

import { useTranslation } from "react-i18next";

import {
  CleanIcon,
  CustomToolbar,
  IconAction,
  InputDate,
  Label,
  NewIcon,
  CustomFormControl,
  ContainerSearch,
  ContainerInputsDate,
  ContainerGroup,
  ContainerGroupSelects,
  ContainerFiltros,
  CustomSelect,
  CustomInputLabel,
  CustomMenuItem,
  CustomBadge,
  ContainerDates,
  ContainerSwitch,
  LabelSwitch,
  CustomSwitch,
  CustomBtnFilter,
} from "./styles";

import { Forms, ClearIconButton, SearchButton } from "../../../../pages/styles";
import { Search } from "@mui/icons-material";
import { FormatDate } from "../../../../utils/formatDate";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../../../Context/PermissionsContext";
import dayjs from "dayjs";

export function TableToolbarWorkflow(props) {
  const {
    arrayCombined,
    filterPlants,
    filterCompany,
    hideFlowBtn,
    handleChangeFilters,
    handleFilters,
    filters,
    handleClearFilters,
    handleCreateWorkflow,
    finalDate,
    initialDate,
    setOpenFilterWorkflowModal,
    workflow,
  } = props;

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;

  dayjs.locale('es');
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const [searchPlantText, setSearchPlantText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState("");
  const [searchCompanyText, setSearchCompanyText] = useState("");

  const containsText = (text, search) => text?.toLowerCase().indexOf(search.toLowerCase()) > -1;

  const optionsPlants = React.useMemo(() => filterPlants?.filter((option) => containsText(option.name, searchPlantText)) || [],
    [filterPlants, searchPlantText]);

  const optionsStatus = React.useMemo(() => arrayCombined?.filter((option) => containsText(option.title, searchStatusText)) || [],
    [arrayCombined, searchStatusText]);

  const optionsCompany = React.useMemo(() => filterCompany?.filter((option) => containsText(option.name, searchCompanyText)) || [],
    [filterCompany, searchCompanyText]);

  const MenuPropsSelect = (needAuto) => {

    const MenuProps = {
      autoFocus: false,
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: needAuto ? 'auto' : 250,
          left: needAuto && 1500
        },
      },
    };

    return MenuProps;
  }

  const applyFilters = async () => {
    await handleFilters();
  }

  return (
    <CustomToolbar>
      <Forms
        onSubmit={e => {
          e.preventDefault()
          applyFilters()
        }}
        isManagement={true}
      >

        {handleCreateWorkflow ?
          <Tooltip title={t("workflow.initFlow")}>
            <IconAction
              size="medium"
              aria-label="new"
              onClick={handleCreateWorkflow}
              sx={{ visibility: hideFlowBtn === false ? 'hidden' : 'visible' }}
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
          :
          <div style={{ marginLeft: 5, width: 28 }}></div>
        }

        <ContainerFiltros>

          {workflow.hasOnlyActiveFilter &&
            <ContainerSwitch>
              <LabelSwitch>
                {t("common.onlyActive")}
              </LabelSwitch>
              <CustomSwitch
                checked={filters?.onlyActive}
                id="filter-status-onlyactive"
                onChange={e => handleChangeFilters(e, "onlyActive", "checkbox")}
                size="small"
              />
            </ContainerSwitch>
          }
          <ContainerGroupSelects>
            {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
              <ContainerSwitch>
                <LabelSwitch>
                  {t("common.statusCanceledFilter")}
                </LabelSwitch>
                <CustomSwitch
                  checked={filters?.includeCanceled}
                  id="filter-status-canceled"
                  onChange={e => handleChangeFilters(e, "includeCanceled", "checkbox")}
                  size="small"
                />
              </ContainerSwitch>
            }
            {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
              <ContainerSwitch>
                <LabelSwitch>
                  {t("common.statusClosedFilter")}
                </LabelSwitch>
                <CustomSwitch
                  id="filter-status-closed"
                  checked={filters?.includeClosed}
                  onChange={e => handleChangeFilters(e, "includeClosed", "checkbox")}
                  size="small"
                />
              </ContainerSwitch>
            }
          </ContainerGroupSelects>

          {/* <ContainerGroupSelects> */}
            {/* FILTER PER PLANTS */}
            {ability.can("PlantList") &&
              <CustomFormControl>
                <CustomInputLabel id="filter-plants">
                  {t("common.plantsFilter")}
                </CustomInputLabel>
                <CustomBadge
                  badgeContent={filters?.plants?.length}
                  color="primary"
                >
                  <CustomSelect
                    labelId="filter-plants"
                    id="filter-plants-select"
                    multiple
                    value={filters?.plants || []}
                    onChange={e => handleChangeFilters(e, "plants", "select")}
                    onClose={() => setSearchPlantText("")}
                    renderValue={() => ""}
                    MenuProps={MenuPropsSelect(false)}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder={t("common.search")}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setSearchPlantText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {optionsPlants
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(
                        (tag) => (
                          (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={filters?.plants?.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <ListItemText primary={tag.name} />
                            </CustomMenuItem>
                          )
                        )
                      )}
                  </CustomSelect>
                </CustomBadge>
              </CustomFormControl>
            }

            {/* FILTER PER STATUS */}
            {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
              <CustomFormControl>
                <CustomInputLabel id="filter-status">
                  {t("common.statusFilter")}
                </CustomInputLabel>
                <CustomBadge
                  badgeContent={filters?.status?.length}
                  color="primary"
                >
                  <CustomSelect
                    labelId="filter-status"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filters?.status || []}
                    onChange={e => handleChangeFilters(e, "status", "select")}
                    onClose={() => setSearchStatusText("")}
                    renderValue={() => ""}
                    MenuProps={MenuPropsSelect(true)}
                  >
                    <ListSubheader>
                      <TextField
                        size="small"
                        autoFocus
                        placeholder={t("common.search")}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setSearchStatusText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {optionsStatus
                      .slice()
                      // .sort((a, b) => a.title.localeCompare(b.title))
                      .map(
                        (tag) => (
                          (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={filters?.status?.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <ListItemText primary={tag.title} />
                            </CustomMenuItem>
                          )
                        )
                      )}
                  </CustomSelect>
                </CustomBadge>
              </CustomFormControl>
            }

            <CustomFormControl>
              <CustomInputLabel id="filter-company">
                {t("common.company")}
              </CustomInputLabel>
              <CustomBadge
                badgeContent={filters?.company?.length}
                color="primary"
              >
                <CustomSelect
                  labelId="filter-company"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filters?.company || []}
                  onChange={e => handleChangeFilters(e, "company", "select")}
                  onClose={() => setSearchCompanyText("")}
                  renderValue={() => ""}
                  MenuProps={MenuPropsSelect(true)}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder={t("common.search")}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchCompanyText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {optionsCompany
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(
                      (tag) => (
                        (
                          <CustomMenuItem
                            key={tag.id}
                            value={tag}
                            sx={{ fontSize: "0.6em" }}
                          >
                            <Checkbox
                              checked={filters?.company?.indexOf(tag) > -1}
                              sx={{ width: "30px", height: "30px" }}
                            />
                            <ListItemText primary={tag.name} />
                          </CustomMenuItem>
                        )
                      )
                    )}
                </CustomSelect>
              </CustomBadge>
            </CustomFormControl>

          {/* </ContainerGroupSelects> */}

          <ContainerDates>
            {/* Filter Dates */}
            <ContainerGroup>
              {/* Input desde */}
              <ContainerInputsDate>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label={t("common.initDate")}
                      value={filters?.startDate}
                      minDate={dayjs('2020-03-20')}
                      maxDate={dayjs(finalDate)}
                      onChange={e => handleChangeFilters(e || "", "startDate", "date")}
                      slotProps={{ textField: { size: 'small', error: false } }}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ContainerInputsDate>
              {/* Input hasta */}
              <ContainerInputsDate>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label={t("common.lastDate")}
                      value={filters?.endDate}
                      minDate={dayjs(initialDate)}
                      onChange={(e) => handleChangeFilters(e || "", "endDate", "date")}
                      slotProps={{ textField: { size: 'small', error: false } }}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ContainerInputsDate>
            </ContainerGroup>
          </ContainerDates>


            <ContainerSearch>
              <SearchButton variant="contained" type="submit" isMobile={true}>
                {t("common.search")}
              </SearchButton>

              {/* Icono Search Mobile */}
              <Tooltip title={t("common.search")}>
                <ClearIconButton isFirstClearButton={true} type="submit">
                  <SearchIcon
                    sx={{ height: "20px", width: "20px", color: "white" }}
                  />
                </ClearIconButton>
              </Tooltip>

              <Tooltip title={t("common.cleanFilters")}>
                <ClearIconButton
                  size="small"
                  aria-label="plot"
                  onClick={handleClearFilters}
                >
                  <CleanIcon />
                </ClearIconButton>
              </Tooltip>
            </ContainerSearch>

        </ContainerFiltros>

        <CustomBtnFilter
          variant="contained"
          endIcon={<TuneIcon />}
          onClick={() => setOpenFilterWorkflowModal(true)}
        >
          {t("common.filters")}
        </CustomBtnFilter>
      </Forms>
    </CustomToolbar>
  );
}
