import React from 'react'

// Components
import { Tooltip, InputAdornment, ListSubheader, TextField, } from "@mui/material";

// Component Date
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Search } from "@mui/icons-material";

import { FormButton } from '../../../../pages/styles';

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";


// Styles
import {
  Container,
  Header,
  Body,
  Footer,
  CustomBtnFilter,
  ClearIconButton,
  ContainerInfo,
  CleanIcon,
  ContainerGroup,
  ContainerInputsDate,

  // Select 
  ContainerGroupSelects,
  CustomFormControl,
  CustomInputLabel,
  CustomBadge,
  CustomSelect,
  CustomMenuItem,

  ContainerSwith,
  ContainerSwitch,
  LabelSwitch,
  CustomSwitch,
  ContainerGroupSwitch,
  ContainerBtnClear,
  ContainerGroupDown,
} from './style';

// Translation 
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import moment from "moment";
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';



export const FilterWorkflowsModalCard = ({
  arrayCombined,
  initialDate,
  finalDate,
  filterPlants,
  handleChangeFilters,
  handleFilters,
  filters,
  handleClearFilters,
  setOpenFilterWorkflowModal,
}) => {

  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;

  const [searchPlantText, setSearchPlantText] = React.useState("");
  const [searchStatusText, setSearchStatusText] = React.useState("");

  const containsText = (text, search) => text?.toLowerCase().indexOf(search.toLowerCase()) > -1;

  const optionsPlants = React.useMemo(() => filterPlants?.filter((option) => containsText(option.name, searchPlantText)) || [],
    [filterPlants, searchPlantText]);

  const optionsStatus = React.useMemo(() => arrayCombined?.filter((option) => containsText(option.title, searchStatusText)) || [],
    [arrayCombined, searchStatusText]);

  const MenuPropsSelect = (needAuto) => {
    const MenuProps = {
      autoFocus: false,
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: needAuto ? 'auto' : 250,
          left: needAuto && 1500
        },
      },
    };
    return MenuProps;
  }

  const applyFilters = async () => {
    await handleFilters();
  }



  return (
    <Container>
      <Header>Filtros Avanzados</Header>
      <form
        onSubmit={e => {
          e.preventDefault();
          applyFilters();
          setOpenFilterWorkflowModal(false);
        }}
      >
        <Body>
          <ContainerInfo>

            <ContainerGroup>
              {/* Input desde */}
              <ContainerInputsDate>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label={t("common.initDate")}
                      minDate={dayjs('2020-03-20')}
                      maxDate={dayjs(finalDate)}
                      onChange={e => handleChangeFilters(e, "startDate", "text")}
                      slotProps={{ textField: { size: 'small' } }}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ContainerInputsDate>
              {/* Input hasta */}
              <ContainerInputsDate>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label={t("common.lastDate")}
                      minDate={dayjs(initialDate)}
                      maxDate={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
                      onChange={e => handleChangeFilters(e, "endDate", "text")}
                      slotProps={{ textField: { size: 'small' } }}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ContainerInputsDate>
            </ContainerGroup>


            <ContainerGroupSelects>
              {/* FILTER PER PLANTS */}
              {ability.can("PlantList") &&
                <CustomFormControl>
                  <CustomInputLabel id="filter-plants">
                    {t("common.plantsFilter")}
                  </CustomInputLabel>
                  <CustomBadge
                    badgeContent={filters?.plants?.length}
                    color="primary"
                  >
                    <CustomSelect
                      labelId="filter-plants"
                      id="filter-plants-select"
                      multiple
                      value={filters?.plants || []}
                      onChange={e => handleChangeFilters(e, "plants", "select")}
                      onClose={() => setSearchPlantText("")}
                      renderValue={() => ""}
                      MenuProps={MenuPropsSelect(false)}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder={t("common.search")}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => setSearchPlantText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {optionsPlants
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(
                          (tag) => (
                            (
                              <CustomMenuItem
                                key={tag.id}
                                value={tag}
                                sx={{ fontSize: "0.6em" }}
                              >
                                <Checkbox
                                  checked={filters?.plants?.indexOf(tag) > -1}
                                  sx={{ width: "30px", height: "30px" }}
                                />
                                <ListItemText primary={tag.name} />
                              </CustomMenuItem>
                            )
                          )
                        )}
                    </CustomSelect>
                  </CustomBadge>
                </CustomFormControl>
              }

              {/* FILTER PER STATUS */}
              {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                <CustomFormControl>
                  <CustomInputLabel id="filter-status">
                    {t("common.statusFilter")}
                  </CustomInputLabel>
                  <CustomBadge
                    badgeContent={filters?.status?.length}
                    color="primary"
                  >
                    <CustomSelect
                      labelId="filter-status"
                      id="demo-multiple-checkbox"
                      multiple
                      value={filters?.status || []}
                      onChange={e => handleChangeFilters(e, "status", "select")}
                      onClose={() => setSearchStatusText("")}
                      renderValue={() => ""}
                      MenuProps={MenuPropsSelect(true)}
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder={t("common.search")}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => setSearchStatusText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {optionsStatus
                        .slice()
                        // .sort((a, b) => a.title.localeCompare(b.title))
                        .map(
                          (tag) => (
                            (
                              <CustomMenuItem
                                key={tag.id}
                                value={tag}
                                sx={{ fontSize: "0.6em" }}
                              >
                                <Checkbox
                                  checked={filters?.status?.indexOf(tag) > -1}
                                  sx={{ width: "30px", height: "30px" }}
                                />
                                <ListItemText primary={tag.title} />
                              </CustomMenuItem>
                            )
                          )
                        )}
                    </CustomSelect>
                  </CustomBadge>
                </CustomFormControl>
              }
            </ContainerGroupSelects>

            <ContainerSwith>

              <ContainerGroupDown>
                <ContainerGroupSwitch>
                  {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                    <ContainerSwitch>
                      <LabelSwitch>
                        {t("common.statusCanceledFilter")}
                      </LabelSwitch>
                      <CustomSwitch
                        checked={filters?.includeCanceled}
                        id="filter-status-canceled"
                        onChange={e => handleChangeFilters(e, "includeCanceled", "checkbox")}
                        size="small"
                      />
                    </ContainerSwitch>
                  }

                  {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                    <ContainerSwitch>
                      <LabelSwitch>
                        {t("common.statusClosedFilter")}
                      </LabelSwitch>
                      <CustomSwitch
                        id="filter-status-closed"
                        checked={filters?.includeClosed}
                        onChange={e => handleChangeFilters(e, "includeClosed", "checkbox")}
                        size="small"
                      />
                    </ContainerSwitch>
                  }
                </ContainerGroupSwitch>

                <ContainerBtnClear>
                  <Tooltip title={t("common.cleanFilters")}>
                    <ClearIconButton
                      size="small"
                      aria-label="plot"
                      onClick={handleClearFilters}
                    >
                      <CleanIcon />
                    </ClearIconButton>
                  </Tooltip>
                </ContainerBtnClear>
              </ContainerGroupDown>

            </ContainerSwith>

          </ContainerInfo>
        </Body>

        <Footer>
          <FormButton variant='outlined' type='close' onClick={() => setOpenFilterWorkflowModal(false)}>
            {t('common.cancel')}
          </FormButton>

          <CustomBtnFilter variant='contained' type='submit'>
           {t("common.applyFilter")}
          </CustomBtnFilter>

        </Footer>
      </form>
    </Container>
  )
}
