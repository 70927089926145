import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

import {
  CustomTableCell,
  FigureHead,
  Text,
  Label,
  InputDate,
  TableContent,
  ContainerActions,
} from "./styles";

import { ColorsContext } from "../../../../Context/ColorsContext";
import { FormatDate } from "../../../../utils/formatDate";
import { StickyColumn, CustomCell } from "../../../../styles/GlobalStyle";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PeopleIcon from '@mui/icons-material/People';

// Utils
import { useTranslation } from "react-i18next";

//Hooks
import { useSortByColumn } from "../../../../Hooks/useSortByColumn";
import { MediaQuerys, ContainerSpeedDial } from "../../../../styles/GlobalStyle";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../../../Context/PermissionsContext";

import MenuSpeedDial from "../../SpeedDial/SpeedDial";

const headCells = [
  {
    id: "id",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.id",
  },
  {
    id: "name",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.names",
  },
  {
    id: "description",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.description",
  },
  {
    id: "isExternal",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.isExternal",
    customSort: 'isExternal ? "Si" : "No"',
  },
  {
    id: "isRestricted",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.isRestricted",
    customSort: 'isRestricted ? "Si" : "No"',
  },
  {
    id: "status",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.status",
    customSort: 'isActive ? "Activo" : "Inativo"',
  },
  {
    id: "created_at",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.permissions.table.createdDate",
    customSort: 'createdAt',
  },
  {
    id: "actions",
    center: true,
    disablePadding: true,
    sortArrow: false,
    label: "common.actions.title",
    sticky: true,
    direction: 'right',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell.id}
            align={headCell.center ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            dataHead={headCell}
          >
            {headCell.sortArrow == true && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? <Box component="span"></Box> : null}
              </TableSortLabel>
            )}
            {headCell.sortArrow == false && (
              <>
                <Box component="span">{t(headCell.label) || headCell.label}</Box>
              </>
            )}
          </CustomCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TableRoles({
  filteredRequest,
  rows,
  selectedRole,
  addDataSpeedDial,
}) {

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { format } = new FormatDate();
  const { t } = useTranslation();
  const { mobile } = MediaQuerys;
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const { order, orderBy, stableSort, getComparator, handleRequestSort } = useSortByColumn('createdAt');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{ width: "100%", marginTop: "16px", [mobile]: { marginTop: "15px" } }}
    >
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableContent>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(filteredRequest, getComparator())
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id} sx={{ verticalAlign: 'super' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ border: "none" }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>{row.name}</TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.description}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.isExternal ? t("common.yes") : t("common.no")}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.isRestricted ? t("common.yes") : t("common.no")}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Text enabled={row.isActive}>
                          {row.isActive
                            ? t("system.permissions.table.active")
                            : t("system.permissions.table.inactive")}
                        </Text>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {format({ date: row.createdAt, format: true})}
                      </TableCell>

                      <StickyColumn direction='right'>
                        
                        <ContainerSpeedDial>
                          <MenuSpeedDial options={addDataSpeedDial(row)}/>
                        </ContainerSpeedDial>

                        <ContainerActions>
                          <Tooltip
                            title={t(
                              "system.permissions.table.assignedPermissions"
                            )}
                          >
                            <IconButton
                              size="small"
                              sx={{
                                borderRadius: "10%",
                                margin: "0 5px",
                                backgroundColor: colors.background,
                                opacity: 0.8,
                                color: colors.buttonActionColor,
                                "&:hover": {
                                  backgroundColor: colors.buttonActionColor,
                                  opacity: 1,
                                  color: colors.white,
                                },
                              }}
                              aria-label="detail"
                              onClick={() => selectedRole(row, "VIEW")}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          {row.isActive && (
                            <>
                              {ability.can("UpdateRole") &&
                                <Tooltip
                                  title={t("system.permissions.table.editRole")}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      borderRadius: "10%",
                                      margin: "0 5px",
                                      backgroundColor: colors.background,
                                      opacity: 0.8,
                                      color: colors.buttonActionColor,
                                      "&:hover": {
                                        backgroundColor: colors.buttonActionColor,
                                        opacity: 1,
                                        color: colors.white,
                                      },
                                    }}
                                    aria-label="detail"
                                    onClick={() => selectedRole(row, "UPDATE")}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              }

                              {ability.can("ToggleStateRole") &&
                                <Tooltip
                                  title={t(
                                    "system.permissions.table.inactivateRole"
                                  )}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      borderRadius: "10%",
                                      margin: "0 5px",
                                      backgroundColor: colors.background,
                                      opacity: 0.8,
                                      color: colors.buttonActionColor,
                                      "&:hover": {
                                        backgroundColor: colors.buttonActionColor,
                                        opacity: 1,
                                        color: colors.white,
                                      },
                                    }}
                                    aria-label="detail"
                                    onClick={() =>
                                      selectedRole(row, "DEACTIVATE")
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </>
                          )}
                          {!row.isActive && (
                            <>                            
                              {ability.can("ToggleStateRole") &&
                                <Tooltip
                                  title={t("system.permissions.table.activateRole")}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      borderRadius: "10%",
                                      margin: "0 5px",
                                      backgroundColor: colors.background,
                                      opacity: 0.8,
                                      color: colors.buttonActionColor,
                                      "&:hover": {
                                        backgroundColor: colors.buttonActionColor,
                                        opacity: 1,
                                        color: colors.white,
                                      },
                                    }}
                                    aria-label="detail"
                                    onClick={() => selectedRole(row, "ACTIVATE")}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </>
                          )}
                          {(ability.can("PermissionsList") && ability.can("FindPermissionsByRole")) &&
                            <Tooltip
                              title={t(
                                "system.permissions.table.assignPermissions"
                              )}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  borderRadius: "10%",
                                  margin: "0 5px",
                                  backgroundColor: colors.background,
                                  opacity: 0.8,
                                  color: colors.buttonActionColor,
                                  "&:hover": {
                                    backgroundColor: colors.buttonActionColor,
                                    opacity: 1,
                                    color: colors.white,
                                  },
                                }}
                                aria-label="detail"
                                onClick={() => selectedRole(row, "ASSIGN")}
                              >
                                <LockOpenIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          
                          {ability.can("GetRelationRoles") &&
                            <Tooltip
                              title={t(
                                "system.permissions.table.relatePermissions"
                              )}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  borderRadius: "10%",
                                  margin: "0 5px",
                                  backgroundColor: colors.background,
                                  opacity: 0.8,
                                  color: colors.buttonActionColor,
                                  "&:hover": {
                                    backgroundColor: colors.buttonActionColor,
                                    opacity: 1,
                                    color: colors.white,
                                  },
                                }}
                                aria-label="detail"
                                onClick={() => selectedRole(row, "RELATE")}
                              >
                                <PeopleIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </ContainerActions>
                      </StickyColumn>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableContent>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("common.itemsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return "" + from + "-" + to + ` ${t("common.of")} ` + count;
          }}
          sx={{
            borderTop: "1px solid " + colors.gray,
          }}
        />
      </Paper>
    </Box>
  );
}
