// eslint-disable-next-line import/no-anonymous-default-export

const Router = {
    // App
    appBaseUrl: process.env.REACT_APP_URL,
    appLogin: '/login',
    appWorkPermitDetail: '/work-permit/detail',
    appPlotPlans: '/plot-plans',
    appCreatePlotPlans: '/plot-plans/create',
    appCreateForm: '/create-form',
    appCreateFormTwo: '/create-form-two',
    appDynamicForm: '/dynamic-form',
    appFormManagement: '/form-management',
    appFormManagementV2: '/form-management-v2',
    appFormManagementDetail: '/form-management/detail',
    appFormManagementDetailV2: '/form-management-v2/detail',
    appWorkflowManagement: '/workflow-management',
    appStandardWorkflowManagement: '/standard-workflow-management',
    appWorkflowManagementDetail: '/workflow-management/detail',
    appWorkflowStatus: '/workflow-status/:id',
    appWorkflowStatusDetail: '/workflow-status/detail/:id',
    appWorkflow: '/workflow/:id',
    appWorkflowDetail: '/workflow/:workflowId/:id',
    appMaintenance: '/maintenance',
    appUsers: '/users',
    applogs: '/logs',
    appPermissions: '/permissions',
    appForbidden: '/forbidden',
    appPasswordPolicy: '/password-policy',
    appAppearance: '/appearance',
    appInactivityTime: '/inactivity-time',
    appSettings: '/settings',
    appLists: '/lists',
    appListDetail: '/lists/detail',
    appMyWork: '/my-work',
    appPasswordRecovery: '/password-recovery',
    appPasswordReset: '/password-reset',
    appExternalActions: '/execute-action',
    appExternalDetailInstance: '/external-detail-instance',
    appExternalDynamicForm: '/external-dynamic-form',
    appDashboardReport: '/dashboard-report',
    appDeviationsReport: '/deviations-report',
    appContract: '/contract',
    appCompanies: '/companies',
    appCompanyWorker: '/workers',
    appStaticAppearance: '/Statics/Appearance',
    appSigninAzure: '/signin-azuread',

    // API
    apiBaseUrl: process.env.REACT_APP_API_URL,
    apiPlotPlan: '/api/workpermit/PlotPlan',
    apiPlotPlanDetail: '/api/workpermit/PlotPlan/Detail/:id',
    apiPlotPlanUpdate: '/api/workpermit/PlotPlan/:id',
    apiPlotPlanDelete: '/api/workpermit/PlotPlan/:id',
    apiPlotPlanHistorical: '/api/workpermit/PlotPlan/Historical',
    apiPlotPlanByWorkflow: '/api/workpermit/PlotPlan/ByWorkflow',
    apiPlotPlanAreaType: '/api/workpermit/PlotPlanAreaType',
    apiWorkPermit: '/api/workpermit/WorkPermit',
    apiIsolationPlan: '/api/workpermit/IsolationPlan/OData',
    apiIsolationPlanDetail: '/api/workpermit/IsolationPlan/:id',
    apiWorkflow: `${process.env.REACT_APP_API_URL}/api/workpermit/Workflow`,
    apiWorkflowData: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData/All`,
    apiWorkflowDataPaginated: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData/Paginated`,
    apiWorkflowDataRelation: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData/Relations`,
    apiWorkflowDataCreate: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData`,
    apiWorkflowDataDelete: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData`,
    apiWorkflowDataDownload: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowData/Download`,
    apiWorkflowStatus: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowStatus/`,
    apiWorkflowStatusAllByWorkflow: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowStatus/all/:id`,
    apiWorkflowStatusDetail: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowStatus/Detail/:id`,
    apiWorkflowStatusStandard: `/api/workpermit/WorkflowStatus/standard`,
    apiWorkflowListAll: '/api/workpermit/Workflow/All',
    apiWorkflowListAllParentChild: '/api/workpermit/Workflow/allParentChild',
    apiWorkflowStatusTypes: '/api/workpermit/WorkflowStatus/Types',
    apiWorkflowStatusRoleExecuteOptions: '/api/workpermit/WorkflowStatus/RoleExecuteOptions',
    apiDetailWorkflowData: '/api/workpermit/WorkflowData/Detail/:id',
    apiWorkflowDataFillForm: '/api/workpermit/WorkflowData/FillForm',
    apiWorkflowDataGetForm: '/api/workpermit/WorkflowData/GetForm',
    apiWorkflowDataGetFormData: '/api/workpermit/WorkflowData/GetFormData',
    apiWorkflowDataSaveFormData: '/api/workpermit/WorkflowData/SaveFormData',
    apiWorkflowDataUpdateStatus: '/api/workpermit/WorkflowData/UpdateStatus',
    apiWorkflowDataCheckForm: '/api/workpermit/WorkflowData/CheckForm',
    apiWorkflowDataCheckFormCredentials: '/api/workpermit/WorkflowData/CheckFormCredentials',
    apiWorkflowDataUploadAttachments: '/api/workpermit/WorkflowData/UploadAttachments',
    apiWorkflowDataClone: '/api/workpermit/WorkflowData/Clone',
    apiWorkflowDataFileUploadFiles: '/api/workpermit/WorkflowDataFile/UploadFiles',
    apiWorkflowDataFileDetail: '/api/workpermit/WorkflowDataFile/:id',
    apiWorkflowDataFile: '/api/workpermit/WorkflowDataFile',
    apiNotification: '/api/workpermit/Notification',
    apiProcessNotification: '/api/workpermit/Notification/:id',
    apiDetailNotification: '/api/workpermit/Notification/Detail/:id',
    apiNotificationByHash: '/api/workpermit/Notification/ByHash',
    apiForm: '/api/workpermit/Form',
    apiFormDetail: '/api/workpermit/Form/:id',
    apiWorkflowDetail: `${process.env.REACT_APP_API_URL}/api/workpermit/Workflow/Detail/:id`,
    apiWorkflowChangeOrder: `${process.env.REACT_APP_API_URL}/api/workpermit/Workflow/Order`,
    apiWorkflowRelations: `${process.env.REACT_APP_API_URL}/api/workpermit/Workflow/Relations/:id`,
    apiWorkflowDefault: '/api/workpermit/Workflow/Default/:id',
    apiHealthCheck: '/api/workpermit/HealthCheck',
    apiLogin: '/api/identity/security/login',
    apiLoginAD: '/api/identity/security/loginAD',
    apiCheckLogin: '/api/identity/security/checkLogin',
    apiLogout: '/api/identity/security/logout',
    apiSignup: '/api/identity/security/signup',
    apiUser: '/api/identity/users',
    apiUserPaginated: '/api/identity/users/paginated',
    apiGetUserFilter: '/api/identity/users/GetUser/:filter',
    apiUserDownload: '/api/identity/users/download',
    apiRole: '/api/identity/roles',
    apiPermission: '/api/identity/permissions',
    apiPasswordPolicy: '/api/identity/PasswordPolicy',
    apiInactivityTime: '/api/identity/InactivityTime',
    apiInactivityTimeRestricted: '/api/identity/InactivityTime/Restricted',
    apiList: '/api/workpermit/List',
    apiListDetail: '/api/workpermit/List/:id',
    apiPasswordRecovery: '/api/identity/users/forgotPassword',
    apiDashboardByWorkflow: '/api/workpermit/Dashboard/ByWorkflow',
    apiDashboardByPlotPlan: '/api/workpermit/Dashboard/ByPlotPlan',
    apiDashboardByStatus: '/api/workpermit/Dashboard/ByStatus',
    apiDashboardByCreated: '/api/workpermit/Dashboard/ByCreated',
    apiDashboardDownloadExcel: '/api/workpermit/Dashboard/DownloadExcel',
    apiDashboardTable: '/api/workpermit/Dashboard/Table',
    apilogsList: '/api/logs/Log',
    apiAppearance: '/api/workpermit/Appearance',
    apiAppearanceRestricted: '/api/workpermit/Appearance/Restricted',
    apiPlantManagementReport: '/api/workpermit/PlantManagement/PlantManagementReport',
    apiPlantManagementCompany: '/api/workpermit/PlantManagement/PlantManagementCompany',
    apiManagementReport: '/api/workpermit/PlantManagement/Report',
    apiReportCompany: '/api/workpermit/PlantManagement/ReportCompany',
    apiListDeviations: '/api/workpermit/PlantManagement/ListDeviation',
    apiDownloadRevalidation: '/api/workpermit/WorkflowData/DownloadRevalidations',
    apiWorkflowStatusSelect: `${process.env.REACT_APP_API_URL}/api/workpermit/WorkflowStatus/Select/:id`, 

    // Plants
    apiPlant: '/api/workpermit/Plant',
    apiPlantsByUser: '/api/workpermit/Plant/UserPlants',

    // Plants Categories
    apiPlantCategory: '/api/workpermit/PlantCategory',

    // Contract
    apiContract: '/api/workpermit/Contract',
    apiContractDetail: '/api/workpermit/Contract/Detail/:id',
    apiContractUpdate: '/api/workpermit/Contract/UpdateContract/:id',
    apiContractDelete: '/api/workpermit/Contract/:id',
    apiRemoveContractor: '/api/workpermit/Contract/RemoveContractor/:id',
    apiContractTechnicalSupervisors: '/api/workpermit/Contract/TechnicalSupervisorsContract',
    apiUploadFilesTechnicalSupervisors: '/api/workpermit/Contract/AttachmentSupervisor',
    apiContractorData: '/api/workpermit/contract/contractors',
    apiContractUpdateContractor: '/api/workpermit/contract/updateContractor',
    apiContractorExecutors: '/api/workpermit/contract/contractorExecutor',
    apiTechnicalSupervisors: '/api/workpermit/contract/technicalSupervisors',
    apiContractAttachments: '/api/workpermit/contract/attachments',
    apiContractAttachmentsContractSupervisor: '/api/workpermit/contract/AttachmentsContractSupervisor',

    // Contract document
    apiContractDocument: '/api/workpermit/ContractDocument',

    // User Attachment 
    apiUserAttachment: '/api/workpermit/userAttachment',

    //Companies
    apiCompany: '/api/workpermit/Company',

    //CompanyWorkers
    apiCompanyWorker: '/api/workpermit/CompanyWorker',

    // WorkerCertificates
    apiWorkerCertificate: '/api/workpermit/WorkerCertificate',

    // ChangeControlType
    apiChangeControlType: '/api/workpermit/ChangeControlType'
}

export default Router;