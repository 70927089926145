import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Router from '../../../../router';

//Utils
import { ColorsContext, useColors } from "../../../../Context/ColorsContext"
import { FormatDate } from '../../../../utils/formatDate';

//Hooks
import { useSortByColumn } from '../../../../Hooks/useSortByColumn';

import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import LocationDisabledOutlinedIcon from '@mui/icons-material/LocationDisabledOutlined';
import { Button, Grid, Icon, Tooltip } from '@mui/material';
import { LoadingProgress } from '../../../LoadingProgress';
import { getIcon, getIconColor } from '../../../../utils/iconConfig';
import { useTranslation } from 'react-i18next'
import { MediaQuerys } from '../../../../styles/GlobalStyle';
import { Forms } from '../../../../pages/styles';
import { StickyColumn, CustomCell } from '../../../../styles/GlobalStyle';

import {
  TableCellStatus,
  CustomTableCell,
  ContainerCell,
  Figure,
  FigureHead,
  SectionInfo,
  InfoCell,
  Label,
  InputDate,
  Cell,
  IconAction,
  CleanIcon,
  NewIcon,
  IconContainer,
  TableContent,
  ContainerSearch,
  ContainerInputsDate,
  ContainerGroup,
  CustomSave,
  SaveOrderButtons,
  ContainerContent,
  ContainerSaveButton,
  GroupActions,
  CustomToolbar
} from './styles'
import { AddCircle, AddToQueue, Delete, Edit, ArrowUpward, ArrowDownward, Save, TroubleshootTwoTone } from '@mui/icons-material';
import { CustomIcon } from '../../../../layouts/Sidebar/styles';
import { ClearIconButton, SearchButton } from '../../../../pages/styles';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext'

function getCheck(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  if (props === true) return <CheckCircleOutlineOutlinedIcon sx={{ color: colors.grayFont }} fontSize="medium" />
}

function getUrlPlot(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  if (props === '') return <LocationDisabledOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
  if (props != '') return <LocationSearchingOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const [headCells, setHeadCells] = React.useState([]);

  React.useEffect(() => {
    returnHeaders();
  }, [])

  const returnHeaders = () => {
    let heads = [
      {
        id: 'id',
        center: false,
        disablePadding: false,
        sortArrow: true,
        label: 'workflowManagement.table.number',
        sticky: true,
        direction: 'left',
      },
      {
        id: 'name',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.table.name'
      },
      {
        id: 'prefix',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.table.prefix'
      },
      {
        id: 'icon',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.table.icon'
      },
      {
        id: 'integrationKey',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.table.integrationKey'
      },
      {
        id: 'lastUpdated',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.table.lastUpdate',
        customSort: 'updatedAt',
      }
    ];

    if (ability.can("UpdateOrderWorkflow")) {
      heads.splice(1, 0, addOrderRow())
    }
    if (ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList") || ability.can("UpdateWorkflow") || ability.can("DeleteWorkflow")) {
      heads.push(addActionsRow())
    }

    setHeadCells(heads);
  }

  function addOrderRow() {
    return {
      id: 'change_order',
      center: false,
      disablePadding: true,
      sortArrow: false,
      label: 'workflowManagement.table.changeOrder'
    }
  }

  function addActionsRow() {
    return {
      id: 'actions',
      center: true,
      disablePadding: true,
      sortArrow: false,
      label: 'common.actions.title',
      sticky: true,
      direction: 'right',
    }
  }

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell.id}
            align={headCell.center ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            dataHead={headCell}
          >
            {headCell.sortArrow == true &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === 'desc' ? '' : ''}
                  </Box>
                ) : null}
              </TableSortLabel>
            }
            {headCell.sortArrow == false &&
              <Box component="span">
                {t(headCell.label) || headCell.label}
              </Box>
            }
          </CustomCell>
        ))}
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

  return (
    <CustomToolbar>
      <Forms ms onSubmit={props.handleDatesFilter} isManagement={true}>
        {ability.can("CreateWorkflow") ?
          <Tooltip title={t('workflowManagement.table.createNew')}>
            <IconAction
              size="medium"
              aria-label="new"
              onClick={props.handleCreateWorkflow}
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
          :
          <div sx={{marginLeft: 5}}></div>
        }
          
            <ContainerContent>
                <ContainerSaveButton>
                  <SearchButton
                    variant="contained"
                    endIcon={<CustomSave/>}
                    aria-label="save orders"
                    onClick={props.handleSaveOrder}
                    management={true}
                  >
                    {t('workflowManagement.table.saveOrder')}
                  </SearchButton>
                </ContainerSaveButton>

            </ContainerContent>
      </Forms>
    </CustomToolbar>
  );
}

EnhancedTableToolbar.propTypes = {
};


export default function TableWorkflowManagement({
  filteredRequest,
  rows,
  loading,
  handleCreateWorkflow,
  handleDelete,
  handleOrderUp,
  handleOrderDown,
  handleSaveOrder
}) {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { format } = new FormatDate();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { order, orderBy, getComparator, stableSort, handleRequestSort } = useSortByColumn('updatedAt');

  const [page, setPage] = React.useState(0);
  const [dense,] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          handleCreateWorkflow={handleCreateWorkflow}
          handleSaveOrder={handleSaveOrder}
        />
        {loading && <LoadingProgress />}
        {!loading &&
          <React.Fragment>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableContent>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                      rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(filteredRequest, getComparator())
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <StickyColumn direction='left' sx={{ zIndex: 1 }}>{row.id}</StickyColumn>

                          {ability.can("UpdateOrderWorkflow") &&
                            <Cell>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Tooltip title={t('workflowManagement.table.moveUp')}>
                                    <span>
                                      <IconContainer
                                        size="small"
                                        aria-label="up"
                                        disabled={index != 0 ? false : true}
                                        onClick={() => handleOrderUp(index, row.order)}
                                      >
                                        <ArrowUpward />
                                      </IconContainer>
                                    </span>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <Tooltip title={t('workflowManagement.table.moveDown')}>
                                    <span>
                                      <IconContainer
                                        size="small"
                                        aria-label="down"
                                        disabled={index != rows.length - 1 ? false : true}
                                        onClick={() => handleOrderDown(index, row.order)}
                                      >
                                        <ArrowDownward />
                                      </IconContainer>
                                    </span>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Cell>
                          }
                 
                          <Cell component="th" scope="row">
                            {row.name}
                          </Cell>
                          <Cell>{row.prefix}</Cell>
                          <Cell>
                            <CustomIcon baseClassName="material-icons-outlined">{row.icon || ''}</CustomIcon>
                          </Cell>
                          <Cell component="th" scope="row">
                            {row.integrationKey}
                          </Cell>
                          <Cell>
                            { format({ date: row?.updatedAt, format: true }) }
                          </Cell>
                          <StickyColumn direction='right'>
                            <GroupActions>
                              {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                                <Grid item>
                                  {/* boton agregar estado*/}
                                  <Tooltip title={t('workflowManagement.table.addFlowState')}>
                                    <IconContainer
                                      size="small"
                                      aria-label="add status"
                                      onClick={() => navigate(Router.appWorkflowStatus.replace(':id', row.id))}
                                    >
                                      <AddToQueue />
                                    </IconContainer>
                                  </Tooltip>
                                </Grid>
                              }
                              {ability.can("UpdateWorkflow") &&
                                <Grid item>
                                  {/* boton edit  */}
                                  <Tooltip title={t('workflowManagement.table.editFlow')}>
                                    <Link to={Router.appWorkflowManagementDetail} state={{ row: row }}>
                                      <IconContainer
                                        size="small"
                                        aria-label="detail"
                                      >
                                        <Edit />
                                      </IconContainer>
                                    </Link>
                                  </Tooltip>
                                </Grid>
                              }
                              {ability.can("DeleteWorkflow") &&
                                <Grid item>
                                  {/* boton delete  */}
                                  <Tooltip title={t('workflowManagement.table.deleteFlow')}>
                                    <IconContainer
                                      size="small"
                                      aria-label="delete"
                                      onClick={() => handleDelete(row)}
                                    >
                                      <Delete />
                                    </IconContainer>
                                  </Tooltip>
                                </Grid>
                              }
                            </GroupActions>
                          </StickyColumn>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableContent>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('common.itemsPerPage')}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ` ${t('common.of')} ` + count
                }
              }
              sx={{
                borderTop: '1px solid ' + colors.gray,
              }}
            />
          </React.Fragment>
        }
      </Paper>
      {/*
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        */}
    </Box>
  );
}

