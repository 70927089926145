import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class WorkflowStatusApi {
    healthCheck = null;
    token = null;

    constructor () {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    // Status Generals per workflows
    async listAllByWorkflow(id, select, minimal){
        return await axios.get(Router.apiWorkflowStatusAllByWorkflow.replace(':id', id), {
            params: {
                select: select,
                minimal: minimal
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    // WorkflowsStandards
    async listFilterStandard(workflowId) {
        return await axios.get(Router.apiBaseUrl + Router.apiWorkflowStatusStandard, {
            params: {
                workflowId: workflowId
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async create(data) {
        return await axios.post(Router.apiWorkflowStatus, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async workflowStatusSelect(id) {
        return await axios.get(Router.apiWorkflowStatusSelect.replace(':id', id))
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async detail(id, workflowId){
        return await axios.get(Router.apiWorkflowStatusDetail.replace(':id', id), {
            params: {
                workflowId: workflowId
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async delete(data) {
        return await axios.delete(Router.apiWorkflowStatus, {data: data})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async edit(data) {
        return await axios.put(Router.apiWorkflowStatus, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async listTypes() {
        return await axios.get(Router.apiBaseUrl + Router.apiWorkflowStatusTypes)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async updateWorkflowStandard(data) {
        return await axios.put(Router.apiBaseUrl + Router.apiWorkflowStatusStandard, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async listRoleExecuteOptions() {
        return await axios.get(Router.apiBaseUrl + Router.apiWorkflowStatusRoleExecuteOptions)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async downloadRevalidation(id, startDate, endDate) {
        return axios.get(Router.apiBaseUrl + Router.apiDownloadRevalidation, {
            headers: { 'Authorization': `Bearer ${this.token}`},
            params: {
                id: id,
                startDate: startDate,
                endDate: endDate,
            },
             responseType: 'blob'
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response);
        })
        .catch(async error => {
            return await this.healthCheck.checkApi(error);
        });
    }
}